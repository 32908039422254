import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"
import { Link } from "gatsby"

export default function Home() {
  return (
    <Layout>
      <Metadata title="Home" description="Coaster Experts Home Page" />
      <h1>Welcome to the Coaster Experts Community</h1>
      <p>
        We are theme park consultants. Experts in human resources and technology
        solutions, we are responsible for helping our clients reform and improve
        their operations and support their business by providing various
        business solutions.
      </p>
      <p>Site under construction, please excuse the mess!</p>
      <h3>
        Do you consider yourself a Coaster Expert?
      </h3>
      <p><Link to="/contact/" >Contact us</Link> to join our team!</p>
      <h4>
        Checkout our <Link to="/blog/" >Blog</Link> and follow our adventures!
      </h4>
    </Layout>
  )
}
